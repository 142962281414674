<template>
    <div class="about">
      <script v-html=navigationJsonLd() type="application/ld+json"/>

        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
                        Gruppen
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Denn wo zwei oder drei versammelt sind in meinem Namen, da bin ich mitten unter ihnen.
                        Matthäus 18,20
                    </h2>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">

                <div class="columns is-multiline">
                    <div class="column">
                        <GruppenCard title="Hauskreis" zielgruppe="Erwachsene" image="gruppen/Hauskreis.jpg"
                                     termine="Jeden 2. Mittwoch"
                                     beschreibung="Rund zehn Hauskreise, verteilt im ganzen Stadtgebiet, bilden die Grundlage für aktives Gemeindeleben auf persönlicher Ebene. Gespräche und Fragen, für die sonntags nach dem Gottesdienst manchmal keine Zeit bleibt, können hier in geschütztem Rahmen behandelt werden. Die Treffen wechseln sich mittwochs mit der Bibel- und Gebetsstunde (in der Gemeinde) ab."
                                     ansprechpartner="Andreas"
                                     ansprechpartnerBild="andreas_h.jpg"
                                     ansprechpartnerMail="hauskreis"
                        />
                    </div>
                    <div class="column">
                        <GruppenCard title="Jungschar" zielgruppe="3. Klasse - 13 Jahre"
                                     image="gruppen/kidspoint.jpg"
                                     termine="Jeden Freitag um 17:30 Uhr"
                                     beschreibung="Spiele, Spaß und Spannung gibts im KidsPoint, der freitags um 17.30 Uhr im Gemeindehaus startet. Kindgerecht werden erste Schritte mit Gott und das Leben mit ihm im Schulalltag mitgegeben. Dabei sind Freundschaften knüpfen und der respektvolle Umgang miteinander genauso wichtig wie das Hören von biblischen Geschichten."
                                     ansprechpartner="Flo"
                                     ansprechpartnerBild="florian.jpg"
                                     ansprechpartnerMail="jungschar"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="Teenies" zielgruppe="12-18 Jahre" image="gruppen/Teenies.jpg"
                                     termine="Jeden Freitag um 19.30 Uhr"
                                     beschreibung="Der Teeniekreis trifft sich freitags um 19.30 Uhr im Jugendraum der CG. In entspannter Atmosphäre werden Freundschaften gepflegt, Probleme ausgetauscht und sich der Frage gestellt, wie man zwischen Schule, Pubertät und Gruppenzwang seinen Glauben authentisch leben kann. Dabei kommen gemeinsame Aktivitäten und Ausflüge nicht zu kurz."
                                     ansprechpartner="Helli"
                                     ansprechpartnerBild="helli.jpg"
                                     ansprechpartnerMail="teens"/>
                    </div>
                    <div class="column">
                        <GruppenCard title="Junge Erwachsene (AC)" zielgruppe="18-30 Jahre" image="gruppen/Ac.jpg"
                                     termine="Dienstags 19:30 Uhr"
                                     beschreibung="
    Die jungen Erwachsenen, auch &quot;AC&quot; genannt, treffen sich immer dienstags, abwechselnd zusammen im Gemeindehaus oder in kleineren Gruppen bei Leuten Zuhause. Beim &quot;Groß-AC&quot; in der Gemeinde steht der Lobpreis und eine Andacht im Mittelpunkt des Abends, die &quot;klein-ac’s&quot; bieten Raum fürs gemeinsame Kochen, persönlichen Austausch über die Bibel, Glaubensfragen und Alltagsprobleme. Im Kalender unter „Veranstaltungen“ sind unsere Treffen eingetragen. Wenn du Interesse hast, mal bei uns vorbei zu schauen, melde dich gerne bei Jonas. Wir freuen uns auf dich!
<br/><br/>
    Und wenn du vorab schon etwas mehr über uns wissen möchtest, <a href='https://www.ac-karlsruhe.de'>klick einfach hier</a>."
                                     ansprechpartner="Jonas"
                                     ansprechpartnerBild="jonas.jpg"
                                     ansprechpartnerMail="ac"/>
                        
                    </div>
                        <div class="column">
                        <GruppenCard title="Spielgruppe" zielgruppe="Eltern mit Kindern von 0-6 Jahre"
                                     image="gruppen/Spielgruppe.jpg"
                                     termine="Jeden Mittwoch von 9:30-11:00 Uhr"
                                     beschreibung="Herzlich Willkommen zur Spielgruppe für Kinder von 0-6 Jahren! Wir freuen uns über viele Kinder samt Eltern, die von 9:30 - 11 Uhr Zeit und Lust haben miteinander zu spielen und zu lachen. Es gibt jede Menge Spielzeug und Platz an dem sich ausprobiert werden kann. Eltern dürfen bei Kaffee und Tee auftanken. Neben dem Singen mit den Kindern gibt es auch einen 5-Minuten-Impuls für Eltern (während die Kinder snacken 😄) weil es uns ein Anliegen ist, dass Eltern ermutigt und froh mit ihrer wertvollen Aufgabe zurück in den Alltag gehen.Die Spielgruppe ist kostenlos. Wir freuen uns auf Euch!"
                                     ansprechpartner="Hanna"
                                     ansprechpartnerBild="hanna_r.jpg"
                                     ansprechpartnerMail="spielgruppe"/>
                    </div>

                    <div class="column">
                        <GruppenCard title="Seniorenkreis" zielgruppe="60+ Jahre" image="gruppen/senioren.jpg"
                                     termine="Jeden 2. Donnerstag"
                                     beschreibung="Der Seniorenkreis trifft sich jeden 2. Donnerstag im Monat um 14.30 Uhr (April bis September um 15 Uhr) im Gemeindehaus und bietet Gelegenheit zum persönlichen Austausch und einer gemeinsamen Zeit mit Gott. Fragen des Älterwerdens und was die Bibel dazu sagt finden genauso Gehör wie Hilfsangebote und Ausflugsmöglichkeiten."
                                     ansprechpartner="Willi"
                                     ansprechpartnerBild="willi_b.jpg"
                                     ansprechpartnerMail="senioren"/>
                    </div>


                </div>
            </div>
        </section>

    </div>

</template>


<script>
// @ is an alias to /src
import GruppenCard from '@/components/GruppeCard.vue'

export default {
        name: 'gruppen',
        components: {
            GruppenCard
        },
        methods: {
          navigationJsonLd() {
            var jsonLdBreadcrumb= {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "CG Karlsruhe",
                "item": "https://www.cg-karlsruhe.de"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Gruppen",
                "item": "https://www.cg-karlsruhe.de/gruppen"
              }]
            };
            return jsonLdBreadcrumb;
          }
        }
    }
</script>